define([
    'lodash',
    'prop-types',
    'santa-components',
    'skins',
    'checkbox/skins/skins.json',
    'componentsCore',
    'santa-core-utils',
    'textCommon'
], function (_, PropTypes, santaComponents, skinsPackage, skinsJson, componentsCore, coreUtilsLib, textCommon) {
    'use strict';

    const getPublicState = function (state) {
        return santaComponents.mixins.validatableMixin.getPublicState(state);
    };

    /**
     * @class components.Checkbox
     * @extends {core.skinBasedComp}
     */
    const checkbox = {
        displayName: 'Checkbox',

        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent,
            groupName: PropTypes.string,
            invalid: PropTypes.bool,
            validityIndication: PropTypes.bool,
            checked: PropTypes.bool,
            onChange: PropTypes.func,
            noneSelected: PropTypes.bool,
            link: santaComponents.santaTypesDefinitions.SiteButton.link,
            impliedLink: santaComponents.santaTypesDefinitions.SiteButton.impliedLink,
            marginBottom: PropTypes.number,
            marginLeft: PropTypes.number,
            marginRight: PropTypes.number
        },

        mixins: [
            componentsCore.mixins.skinBasedComp,
            santaComponents.mixins.runTimeCompData,
            santaComponents.mixins.inputFocusMixin,
            santaComponents.mixins.validatableMixin.validatable,
            santaComponents.mixins.compStateMixin(getPublicState),
            textCommon.textScaleMixin
        ],

        statics: {
            compSpecificIsDomOnlyOverride() {
                return false;
            },
            behaviors: _.assign({},
                santaComponents.mixins.inputFocusMixin.INPUT_FOCUS_BEHAVIORS,
                santaComponents.mixins.validatableMixin.VALIDATABLE_BEHAVIORS
            )
        },

        isPartOfGroup() {
            return !!this.props.groupName;
        },

        focus() {
            this.refs.input.focus();
        },

        blur() {
            this.refs.input.blur();
        },

        setCustomValidity(customValidity) {
            this.refs.input.setCustomValidity(customValidity);
        },

        getInitialState() {
            this.shouldResetComponent = this.props.shouldResetComponent;
            return _.assign(getPublicState(), {
                checked: false
            });
        },

        componentWillMount() {
            this.setState({
                checked: this.props.compData.checked || false
            });
        },

        componentWillReceiveProps(nextProps) {
            if (nextProps.shouldResetComponent && nextProps.shouldResetComponent !== this.shouldResetComponent) {
                this.hideValidityIndication();
            }
            this.setState({checked: nextProps.compData.checked});
            this.shouldResetComponent = nextProps.shouldResetComponent;
        },

        onChange() {
            if (this.props.compProp.readOnly || this.props.compProp.isDisabled) {
                return;
            }

            const updatedData = {checked: !this.state.checked};

            this.setState({checked: !this.state.checked});
            this.updateData(updatedData);
            this.handleAction('change', updatedData);
        },

        onFocus() {
            this.handleAction('focus');
        },

        onBlur() {
            this.handleAction('blur');
            this.showValidityIndication();
        },

        getInputParams() {
            const disabled = !!this.props.compProp.isDisabled;

            return this.isPartOfGroup() ? {
                disabled,
                checked: this.props.checked,
                onChange: this.props.onChange,
                value: this.props.compData.value,
                required: this.props.noneSelected && this.props.compProp.required,
                name: this.props.groupName
            } : {
                onFocus: this.onFocus,
                onBlur: this.onBlur,
                onChange: this.onChange,
                disabled,
                value: this.props.compData.value,
                required: this.props.compProp.required,
                checked: this.state.checked
            };
        },

        getCheckboxStyles() { // eslint-disable-line complexity
            const label = _.get(this.props, 'compData.label', '');
            const alignment = this.props.compProp.alignment || 'left';
            const marginDirection = `margin${_.capitalize(alignment)}`;
            const textStyle = this.props.textStyle || {};
            const checkboxStyle = {};
            const checkmarkStyle = {};
            const buttonSize = this.props.compProp.buttonSize || 18;
            const fontSize = textStyle.fontSize || _.get(this.getFontSize('fnt'), 'fontSize');
            if (fontSize) {
                _.assign(textStyle, {fontSize});
            }
            if (label || this.isPartOfGroup()) {
                _.assign(checkboxStyle, {
                    width: buttonSize,
                    minWidth: buttonSize,
                    height: buttonSize,
                    marginTop: 0
                });
                _.assign(checkmarkStyle, {[marginDirection]: -1 * buttonSize}, checkboxStyle);
                textStyle[marginDirection] = _.get(this.props, 'compProp.spacing', 0) + 1;
            }
            return {checkboxStyle, checkmarkStyle, textStyle};
        },

        getImpliedLink() {
            return this.props.impliedLink;
        },

        getLinkSkinPart() {
            const noLink = {
                parentConst: santaComponents.utils.createReactElement.bind(null, 'span')
            };

            if (!this.props.compData.linkLabel) {
                return _.merge({
                    style: {
                        display: 'none'
                    }
                }, noLink);
            }

            const linkSkinProps = this.props.link;

            if (linkSkinProps) {
                const additionPropsForAccessibility = {
                    role: 'button',
                    onKeyDown: coreUtilsLib.accessibility.keyboardInteractions.activateBySpaceButton
                };
                return _.assign(linkSkinProps, additionPropsForAccessibility);
            }

            return this.getImpliedLink() || noLink;
        },

        getStyle() {
            const {marginBottom, marginRight, marginLeft} = this.props;
            return _.assign(
                {},
                marginBottom && {marginBottom},
                marginLeft && {marginLeft},
                marginRight && {marginRight}
            );
        },

        getSkinProperties() {
            const label = _.get(this.props, 'compData.label', '');
            const classSet = {
                invalid: this.props.invalid,
                'with-label': !!label || this.isPartOfGroup(),
                'with-validation-indication': this.isPartOfGroup() ? this.props.validityIndication : this.shouldShowValidityIndication()
            };
            const alignment = this.props.compProp.alignment || 'left';
            classSet[`${alignment}-direction`] = true;

            const disabled = !!this.props.compProp.isDisabled;
            const error = _.get(this, 'props.compProp.data-error', false);
            const preview = this.getComponentPreviewState() || this.props.previewState;

            const {checkboxStyle, checkmarkStyle, textStyle} = this.getCheckboxStyles();

            return {
                '': {
                    className: this.classSet(classSet),
                    'data-disabled': disabled,
                    'data-preview': preview,
                    'data-has-label': !!label.length,
                    'data-error': error,
                    style: this.getStyle()
                },
                link: this.getLinkSkinPart(),
                linkLabel: {
                    children: [this.props.compData.linkLabel]
                },
                label: {
                    style: textStyle
                },
                text: {
                    children: [label]
                },
                checkbox: {
                    style: checkboxStyle
                },
                checkmark: {
                    style: checkmarkStyle
                },
                input: this.getInputParams()
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.inputs.Checkbox', checkbox);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return checkbox;
});
