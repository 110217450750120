(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.input.CheckboxBasicSkin'] = {
  "react": [
    [
      "label",
      "container",
      [],
      {},
      [
        "input",
        "input",
        [],
        {
          "ref": "input",
          "type": "checkbox"
        }
      ],
      [
        "span",
        "checkbox",
        [],
        {}
      ],
      [
        "div",
        "checkmark",
        [],
        {},
        [
          "svg",
          null,
          [],
          {
            "xmlns": "http://www.w3.org/2000/svg",
            "viewBox": "0 0 7.40011 5.68606"
          },
          [
            "path",
            null,
            [],
            {
              "d": "M2.55016,5.68606c-.01709,0-.03369-.001-.05078-.002a.7.7,0,0,1-.51758-.28955L.1317,2.82326a.7.7,0,1,1,1.13672-.81738l1.36133,1.8916L6.19713.2134A.69995.69995,0,0,1,7.203,1.187L3.05309,5.47268A.7.7,0,0,1,2.55016,5.68606Z"
            }
          ]
        ]
      ],
      [
        "div",
        "label",
        [],
        {},
        [
          "span",
          "text",
          [],
          {}
        ],
        [
          "a",
          "link",
          [],
          {},
          [
            "span",
            "linkLabel",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bgc": "BG_COLOR_ALPHA",
    "brwh": "BORDER_SIZE",
    "brdh": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "bgch": "BG_COLOR_ALPHA",
    "brwf": "BORDER_SIZE",
    "brdf": "BORDER_COLOR_ALPHA",
    "bgf": "BG_COLOR_ALPHA",
    "bge": "BG_COLOR_ALPHA",
    "brde": "BORDER_COLOR_ALPHA",
    "brwe": "BORDER_SIZE",
    "bgd": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "brwd": "BORDER_SIZE",
    "bgcd": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "brw": "0",
    "bg": "color_11",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bgc": "color_1",
    "brwh": "0",
    "brdh": "color_15",
    "bgh": "color_11",
    "bgch": "color_1",
    "brwf": "0",
    "brdf": "color_15",
    "bgf": "color_11",
    "bge": "color_11",
    "brde": "color_15",
    "brwe": "0",
    "bgd": "#CCCCCC",
    "brdd": "#CCCCCC",
    "brwd": "0",
    "bgcd": "color_1",
    "fnt": "font_8",
    "txt": "color_15"
  },
  "css": {
    "%container": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_with-label %container": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;",
    "%checkbox": "border:solid [brd] [brw];background-color:[bg];position:relative;display:block;[rd]  [shd]  height:100%;box-sizing:border-box;pointer-events:none;",
    "%checkmark": "cursor:pointer;display:none;height:100%;position:relative;pointer-events:none;margin-top:-100%;",
    "%checkmark svg": "fill:[bgc];width:50%;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);",
    "%input": "position:absolute;opacity:0;margin:0;cursor:pointer;",
    "%input:checked ~ %checkmark": "display:block;",
    "%container:hover %checkbox,%[data-preview~=\"hover\"] %checkbox": "border:solid [brwh] [brdh];background-color:[bgh];",
    "%container:hover %checkmark svg,%[data-preview~=\"hover\"] %checkmark svg": "fill:[bgch];",
    "%input:focus + %checkbox,%[data-preview~=\"focus\"] %checkbox": "border:solid [brwf] [brdf];background-color:[bgf];",
    "%input:focus + %checkbox + %checkmark svg,%[data-preview~=\"focus\"] %checkbox + %checkmark svg": "fill:[bgc];",
    "%_with-validation-indication %input:not(:focus):invalid ~ %checkbox,%_with-validation-indication%_invalid %checkbox": "background-color:[bge];border:solid [brde] [brwe];",
    "%[data-error=\"true\"] %checkbox,%[data-preview~=\"error\"] %checkbox": "background-color:[bge];border:solid [brde] [brwe];",
    "%[data-disabled=\"true\"] %input,%[data-preview~=\"disabled\"] %input": "cursor:auto;",
    "%[data-disabled=\"true\"] %checkbox,%[data-preview~=\"disabled\"] %checkbox": "background-color:[bgd];border-color:[brdd];border-width:[brwd];",
    "%[data-disabled=\"true\"] %checkmark svg,%[data-preview~=\"disabled\"] %checkmark svg": "fill:[bgcd];",
    "%_with-label%_right-direction": "display:-webkit-box;display:-webkit-flex;display:flex;direction:rtl;",
    "%_with-label%_right-direction %label,%_with-label%_right-direction %container": "direction:rtl;",
    "%_with-label%_left-direction": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:reverse;-webkit-flex-direction:reverse;flex-direction:reverse;",
    "%_with-label%_left-direction %label,%_with-label%_left-direction %container": "direction:ltr;",
    "%label": "[fnt]  color:[txt];line-height:1;word-break:break-word;display:inline-block;-webkit-user-select:none;-ms-user-select:none;user-select:none;",
    "%linkLabel": "text-decoration:underline;",
    "%text": "cursor:pointer;",
    "%link": "color:[txt];",
    "%link:before": "content:\" \";",
    "%linka": "cursor:pointer;"
  }
}

        return skins;
    }));